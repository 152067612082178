import TimerIcon from '@mui/icons-material/Timer'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Rectangle from 'assets/images/rectangle.svg'
import { fontSizes, fontWeights } from 'assets/styles'
import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'

interface CountDownProps {
  countDownDate: Date | string | number
  setElaunchStart: React.Dispatch<boolean>
}

// Completed and return this component
const Completionist = () => <Box>🎉 🎉 🎉</Box>
// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />

  } else if (days > 0) {
    // Render a countdown days only
    return (
      <Box display="flex">
        <Box sx={{ width: "45px" }} display="block" margin="0px 5px 0px 5px">
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_24}
            fontWeight={fontWeights.bold}
            lineHeight="1"
            display="flex"
            justifyContent="center"
          >
            {zeroPad(days)}
          </Typography>
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_10}
            fontWeight={fontWeights.medium}
            display="flex"
            justifyContent="center"
          >
            {'days'}
          </Typography>
        </Box>
      </Box>
    )
  } else if (days === 0 && hours > 0) {
    // Render a countdown hours and minutes only
    return (
      <Box display="flex">
        <Box sx={{ width: "45px" }} display="block" margin="0px 5px 0px 5px">
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_24}
            fontWeight={fontWeights.bold}
            lineHeight="1"
            display="flex"
            justifyContent="center"
          >
            {zeroPad(hours)}
          </Typography>
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_10}
            fontWeight={fontWeights.medium}
            display="flex"
            justifyContent="center"
          >
            {'hours'}
          </Typography>
        </Box>
        <Box sx={{ width: "45px" }} display="block" margin="0px 5px 0px 5px">
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_24}
            fontWeight={fontWeights.bold}
            lineHeight="1"
            display="flex"
            justifyContent="center"
          >
            {zeroPad(minutes)}
          </Typography>
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_10}
            fontWeight={fontWeights.medium}
            display="flex"
            justifyContent="center"
          >
            {'minutes'}
          </Typography>
        </Box>
      </Box>
    )
  } else if (days === 0 && hours === 0) {
    // Render a countdown minutes and seconds only
    return (
      <Box display="flex">
        <Box sx={{ width: "45px" }} display="block" margin="0px 5px 0px 5px">
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_24}
            fontWeight={fontWeights.bold}
            lineHeight="1"
            display="flex"
            justifyContent="center"
          >
            {zeroPad(minutes)}
          </Typography>
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_10}
            fontWeight={fontWeights.medium}
            display="flex"
            justifyContent="center"
          >
            {'minutes'}
          </Typography>
        </Box>
        <Box sx={{ width: "45px" }} display="block" margin="0px 5px 0px 5px">
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_24}
            fontWeight={fontWeights.bold}
            lineHeight="1"
            display="flex"
            justifyContent="center"
          >
            {zeroPad(seconds)}
          </Typography>
          <Typography
            variant="inherit"
            component="div"
            fontSize={fontSizes.fs_10}
            fontWeight={fontWeights.medium}
            display="flex"
            justifyContent="center"
          >
            {'seconds'}
          </Typography>
        </Box>
      </Box>
    )
  }
}

export const CountDownCard = (props: CountDownProps) => {
  const { countDownDate, setElaunchStart } = props;
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      borderRadius="5px"
      mb="15px"
      //   p="12px"
      sx={{
        //boxShadow: 3,
        backgroundImage: `url("${Rectangle}")`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
        backgroundOrigin: 'border-box'
        // background: 'linear-gradient(90deg,#FC4A1A,#F7B733)',
      }}
    >
      <Box width="60%" display="flex" margin="auto" alignItems="center">
        <Box alignItems="center" display="flex" ml="6px">
          <TimerIcon sx={{ color: '#fff' }} />
        </Box>
        <Typography
          component="div"
          ml="6px"
          fontSize={fontSizes.fs_12}
          fontWeight={fontWeights.medium}
          color="white"
        >
          E-Launching Countdown
        </Typography>
      </Box>
      <Box
        width="40%"
        padding="10px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Countdown
          date={countDownDate}
          renderer={renderer}
          zeroPadTime={2}
          onComplete={() => { setElaunchStart(true) }}
        />
      </Box>
    </Box>
  )
}
