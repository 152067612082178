import { BehaviorSubject } from "rxjs";

let navigationSubject = new BehaviorSubject<string>("");

export const navigateTo = (path: string) => {
  navigationSubject.next(path);
};

export const navigationObservable = () => {
  return navigationSubject;
};