import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import * as React from 'react'
export function Spinner() {
  const [open, setOpen] = React.useState(false)
  React.useEffect(() => {
    setOpen(true)
  }, [open])

  return (
    <Backdrop
      sx={{
        color: theme => theme.palette.primary.main,
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
      open={open}
      transitionDuration={2000}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
