import { Spinner } from 'components'
import { MainLayout } from 'containers/app/MainLayout'
import AuthRoutes from 'containers/authentication/Routes'
import { LANDING_PATH } from 'containers/landing/Routes'
import { FC, lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import { PrivateRoute } from './PrivateRoute'
import RootRoutes from './RootRoutes'

/**
 * @param public Routes
 * ex: login , Register  and etc.
 *
 */

const PaymentConfirmation = lazy(() => import('../landing/PaymentConfirmation'))
const PaymentReceipt = lazy(() => import('../landing/PaymentReceipt'))
const TnC = lazy(() => import('../landing/TermAndCondition'))

type RouteProps = {}
export const Routes: FC<RouteProps> = () => {
  return (
    <Switch>
      {AuthRoutes?.map((el: any, index: number) => (
        <Route key={index} {...el.props}>
          <Suspense fallback={<Spinner />}>{el.component}</Suspense>
        </Route>
      ))}

      <Route
        exact={true}
        path={`${LANDING_PATH.PAYMENT_CONFIRMATION}/:type/:projectId/:saleId/:paymentType`}
      >
        <Suspense fallback={<Spinner />}>
          <PaymentConfirmation />
        </Suspense>
      </Route>

      <Route
        exact={true}
        path={`${LANDING_PATH.PAYMENT_CONFIRMATION}/:type/:projectId/:saleId/:paymentType/refund-policy`}
      >
        <Suspense fallback={<Spinner />}>
          <TnC mode={'refund-confirm'} />
        </Suspense>
      </Route>

      <Route
        exact={true}
        path={`${LANDING_PATH.PAYMENT_RESULT}/:refNo/:saleId/:paymentType`}
      >
        <Suspense fallback={<Spinner />}>
          <PaymentReceipt />
        </Suspense>
      </Route>

      {RootRoutes?.map((el: any, index: number) => (
        <PrivateRoute key={index} {...el.props}>
          <MainLayout>
            <Suspense fallback={<Spinner />}>{el.component}</Suspense>
          </MainLayout>
        </PrivateRoute>
      ))}
    </Switch>
  )
}
