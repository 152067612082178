import { ReactNode } from 'react'

const getTranslation = (key: string) => {
  let translationObj = JSON.parse(localStorage.getItem('getTranslation'))
  let translation = ''

  if (translationObj != null) {
    translation = translationObj[key]
  }

  return <>{translation || key}</>
}

export const TranslationServices = {
  getValue(key: string) {
    let translationObj = JSON.parse(localStorage.getItem('getTranslation'))
    let translation = translationObj[key]

    return translation
  },

  Translate(key: string) {
    if (
      JSON.parse(localStorage.getItem('getTranslation')) ==
      (null || '' || undefined)
    ) {
    }
    let translationValue: ReactNode = getTranslation(
      key.toString().toUpperCase()
    )
    return translationValue
  },
}
