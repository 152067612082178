import { createTheme } from '@mui/material/styles'
import { typography } from './Typography'
import { palette } from './Palette'
import { colors } from 'assets/styles'
/**
 * refer: https://material-ui.com/customization/theming/
 * Override material ui default styles
 * Palette
 * Typograhy
 * Spacing
 * Breakpoints
 * Density
 * Z-index
 */
export const Theme = createTheme({
  typography,
  palette,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          background: '#E8EBF4',

          minHeight: '100%',
          fontFamily: 'Poppins, sans-serif',
          code: {
            fontFamily:
              'source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace',
          },
          html: {
            minHeight: '100%',
          },
          u: {
            cursor: 'pointer',
          },
          '.text-seperator': {
            margin: '0px 4px',
          },
          ' .text-icon': {
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            span: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            'svg.MuiSvgIcon-root': {
              marginRight: '4px',
              fontSize: '14px',
              lineHeight: '16px',
            },
            svg: {
              marginRight: '4px',
              fontSize: '14px',
              lineHeight: '16px',
            },
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          background: 'rgb(50, 50, 50)',
          fontWeight: 500,
          color: 'rgba(255, 255, 255, 0.87)',
          '& .MuiSnackbarContent-action .MuiButton-root': {
            color: colors.orange,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          background: '#fff',
          borderRadius: '3px',
          marginBottom: '6px',
          boxShadow:
            '0 3px 1px -2px transparent, 0 1px 10px 2px rgb(0 0 0 / 5%), 0 0 5px 0 rgb(0 0 0 / 10%)',
          flexWrap: 'wrap',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: '0px',
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          width: '100%',
          paddingLeft: '12px',
          paddingTop: '12px',
          paddingBottom: '12px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&.MuiButton-containedPrimary': {
            background: colors.gradientOrange,
          },
          '&.Mui-disabled': {
            background: 'rgba(0,0,0,0.12)',
          },
        },
      },
    },
  },
})
