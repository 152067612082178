export const fontSizes = {
  fs_8: '8px',
  fs_9: '9px',
  fs_10: '10px',
  fs_12: '12px',
  fs_14: '14px',
  fs_16: '16px',
  fs_18: '18px',
  fs_20: '20px',
  fs_24: '24px',
}

export const fontWeights = {
  thin: 100,
  extralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
}

export const lineHeights = {
  lh_10: '10px',
  lh_12: '12px',
  lh_14: '14px',
  lh_16: '16px',
  lh_18: '18px',
  lh_20: '20px',
}
