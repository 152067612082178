import { Button } from '@mui/material'
import { colors, fontWeights } from 'assets/styles'

export const AuthButton = ({ ...props }) => {
  return (
    <Button
      sx={{
        height: '45px',
        borderRadius: '5px',
        fontSize: '15px',
        fontWeight: fontWeights.semibold,
      }}
      {...props}
    />
  )
}
