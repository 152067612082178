export const typography = {
  fontFamily: ['Poppins', 'sans-serif'].join(','),

  // h3: {
  //   fontSize: 16,
  //   fontWeight: 700,
  //   lineHeight: '18px',
  // },
  // h4: {
  //   fontSize: 14,
  //   fontWeight: 700,
  //   lineHeight: '16px',
  // },
  // h5: {
  //   fontSize: 12,
  //   fontWeight: 700,
  //   lineHeight: '14px',
  // },
  // h6: {
  //   fontSize: 10,
  //   fontWeight: 700,
  //   lineHeight: '12px',
  // },
  // subtitle1: {
  //   fontSize: 12,
  //   fontWeight: 600,
  //   lineHeight: '14px',
  // },
  // subtitle2: {
  //   fontSize: 10,
  //   fontWeight: 600,
  //   lineHeight: '12px',
  // },
  // body1: {
  //   fontSize: 12,
  //   fontWeight: 500,
  //   lineHeight: '14px',
  // },
  // body2: {
  //   fontSize: 10,
  //   fontWeight: 500,
  //   lineHeight: '12px',
  // },
}
