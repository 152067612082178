import React, { lazy } from 'react'
// import URL3DDisplay from './URL3DDisplay'
const Landing = lazy(() => import('./Landing'))
const BookingDetail = lazy(() => import('./BookingDetail'))
const JointBuyerList = lazy(() => import('./JointBuyerList'))
const PurchaserDocument = lazy(() => import('./PurchaserDocument'))
const UnitSelection = lazy(() => import('./UnitSelection'))
const BookingForm = lazy(() => import('./BookingForm'))
const EditProfile = lazy(() => import('./EditProfile'))
const ChangePassword = lazy(() => import('./ChangePassword'))
const BrochureList = lazy(() => import('./BrochureList'))
const Public = lazy(() => import('./Public'))
const TnC = lazy(() => import('./TermAndCondition'))
const URL3DDisplay = lazy(() => import('./URL3DDisplay'))

export const LANDING_PATH = {
  ROOT: '/',
  BOOKING_DETAIL: '/booking-detail',
  JOINT_BUYER_LIST: '/joint-buyer',
  PURCHASER_DOCUMENT: '/purchaser-document',
  UNIT_SELECTION: '/booking/unit-select',
  BOOKING_FORM: '/booking/form',
  EDIT_PROFILE: '/my-profile',
  CHANGE_PASSWORD: '/change-password',
  BROCHURE_LIST: '/brochure-list',
  PUBLIC: '/p',
  PUBLIC_BROCHURE_LIST: '/p/brochure-list',
  PAYMENT_CONFIRMATION: '/payment',
  PAYMENT_RESULT: '/payment/payment-result',
  TERMS_AND_CONDITION_REF: '/t&c/refund',
  TERMS_AND_CONDITION_PDPA: '/t&c/pdpa',
  SELECT_PAYMENT: '/select-',
  URL_3D_DISPLAY: '/url-3d-display',
}

const LandingRoutes = [
  {
    props: { exact: true, path: LANDING_PATH.ROOT },
    component: <Landing />,
  },
  {
    props: { exact: true, path: `${LANDING_PATH.BOOKING_DETAIL}/:saleId` },
    component: <BookingDetail />,
  },
  {
    props: { exact: true, path: `${LANDING_PATH.JOINT_BUYER_LIST}/:saleId` },
    component: <JointBuyerList />,
  },
  {
    props: { exact: true, path: `${LANDING_PATH.PURCHASER_DOCUMENT}/:saleId` },
    component: <PurchaserDocument />,
  },
  {
    props: { exact: true, path: LANDING_PATH.UNIT_SELECTION + '/:bookOrFav' },
    component: <UnitSelection />,
  },
  {
    props: { exact: true, path: LANDING_PATH.BOOKING_FORM },
    component: <BookingForm />,
  },
  {
    props: { exact: true, path: LANDING_PATH.EDIT_PROFILE },
    component: <EditProfile />,
  },
  {
    props: { exact: true, path: LANDING_PATH.CHANGE_PASSWORD },
    component: <ChangePassword />,
  },
  {
    props: { exact: true, path: LANDING_PATH.BROCHURE_LIST },
    component: <BrochureList />,
  },
  {
    props: { exact: true, path: LANDING_PATH.PUBLIC },
    component: <Public />,
  },
  {
    props: { exact: true, path: LANDING_PATH.PUBLIC_BROCHURE_LIST },
    component: <BrochureList />,
  },
  {
    props: { exact: true, path: LANDING_PATH.TERMS_AND_CONDITION_REF },
    component: <TnC mode={'refund'} />,
  },
  {
    props: { exact: true, path: LANDING_PATH.TERMS_AND_CONDITION_PDPA },
    component: <TnC mode={'pdpa'} />,
  },
  {
    props: { exact: true, path: LANDING_PATH.URL_3D_DISPLAY },
    component: <URL3DDisplay />,
  },
]

export default LandingRoutes
