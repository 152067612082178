import React from 'react'
import { Box } from '@mui/system'
interface CardContainerProps {
  children: React.ReactNode
}
export const CardContainer = ({ children }: CardContainerProps) => {
  return (
    <Box
      width="100%"
      display="flex"
      flexWrap="wrap"
      padding="10px 12px"
      borderRadius="3px"
      mb="8px"
      bgcolor="#FFF"
      sx={{
        boxShadow: 3,
      }}
    >
      {children}
    </Box>
  )
}
