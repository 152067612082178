import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
export const layoutClasses = {
  root: `root`,
  mainContent: `mainContent`,
  contentShift: `contentShift`,
  toolbar: 'toolbar',
}

export const LayoutRoot = styled(Box)(({ theme }) => ({
  [`& .${layoutClasses.root}`]: {
    display: 'flex',
  },
  [`& .${layoutClasses.mainContent}`]: {
    position: 'relative',
    // top: 130,
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      //   top: 130,
      maxWidth: '1200px',
      margin: '0 auto',
      width: '90vw',
      padding: '24px',
    },
  },
  [`& .${layoutClasses.toolbar}`]: theme.mixins.toolbar,
}))
