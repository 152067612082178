export const colors = {
  white: '#ffffff',
  lightGrey: '#f5f5f5',
  grey: '#7681A1',
  lightBlue: '#E5EDFF',
  gradientBlue: 'linear-gradient(90deg,#2f5cff,#2994FF)',
  gradientBlue50deg: 'linear-gradient(50deg,#2f5cff,#2994FF)',
  gradientBlue0deg: 'linear-gradient(0deg,#2f5cff,#2994FF)',
  gradientGrey: 'linear-gradient(90deg,#586488,#8391B7)',
  orange: '#FF9800',
  lightOrange: '#FFF1DD',
  gradientOrange: 'linear-gradient(90deg,#FFC31F,#FFA700)',
  gradientOrange50deg: 'linear-gradient(50deg,#FFC31F,#FFA700)',
  gradientOrange0deg: 'linear-gradient(0deg,#FFC31F,#FFA700)',
}
