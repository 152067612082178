import { Menu, MenuProps } from '@mui/material'
import { colors } from 'assets/styles'
import React from 'react'

export const ProfileMenu = ({ ...props }: MenuProps) => {
  return (
    <Menu
      {...props}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        '.MuiMenu-paper': {
          marginTop: '8px',
          borderRadius: '16px',
          background: colors.lightOrange,
          boxShadow: '2px 2px 4px #cccccc, -2px -2px 4px #f9f9f9',
        },
      }}
    />
  )
}
