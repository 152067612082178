import { styled } from '@mui/material/styles'
import { Avatar, AvatarProps, Badge, IconButton } from '@mui/material'
import React from 'react'
import { Publish as PublishIcon } from '@mui/icons-material'
export interface AvatarUploadProps extends AvatarProps {
  onChangeAvatarUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const Input = styled('input')({
  display: 'none',
})
export const AvatarUpload = ({
  onChangeAvatarUpload,
  ...props
}: AvatarUploadProps) => {
  return (
    <>
      <Badge
        badgeContent={
          <label htmlFor="icon-button-file">
            <Input
              accept="image/*"
              id="icon-button-file"
              type="file"
              onChange={onChangeAvatarUpload}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              sx={{ boxShadow: 1 }}
            >
              <PublishIcon sx={{ color: '#fff' }} />
            </IconButton>
          </label>
        }
        color="primary"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiBadge-anchorOriginBottomRightRectangular': {
            padding: 0,
            height: 'inherit',
            borderRadius: '50%',
            marginRight: '10px',
            marginBottom: '10px',
          },
        }}
      >
        <Avatar
          {...props}
          sx={{ boxShadow: 2, width: '100px', height: '100px' }}
        />
      </Badge>
    </>
  )
}
