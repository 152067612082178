import React, { ReactNode } from 'react'
import { FC } from 'react'
import { Redirect, Route, useLocation, useHistory } from 'react-router'
import { getAccessToken } from '../../AccessToken';
type Props = {
  children?: ReactNode
}

export const PrivateRoute: FC<Props> = ({ children, ...rest }) => {
  const AccessToken = getAccessToken();
  const { pathname } = useLocation()
  const history = useHistory()

  if(history.location.search) {
    sessionStorage.setItem("params", history.location.search)
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        (AccessToken || pathname.split('/')[1] === 'p') ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}
