import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import ShareIcon from '@mui/icons-material/Share'
import { Box, Button, MenuItem, Typography } from '@mui/material'
import { ReactComponent as ChangePasswordIcon } from 'assets/icons/change-pw.svg'
import { ReactComponent as GoogleMapIcon } from 'assets/icons/google-map.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'
import { ReactComponent as MyProfileIcon } from 'assets/icons/my-profile.svg'
import WhatsAppIcon from 'assets/images/whatsapp.png'
import { colors, fontSizes, fontWeights } from 'assets/styles'
import {
  ContactDialog,
  DialogHeader,
  Header,
  LandingHeader,
  layoutClasses,
  LayoutRoot,
  ProfileMenu,
  ShareDialog,
} from 'components'
import AppContext, { AppContextProps } from 'containers/context/Context'
import { useMenuOption } from 'containers/hooks/useMenuOption'
import * as JWT from 'jwt-decode'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { getAccessToken } from '../../AccessToken'
import {
  useGetELaunchQuery,
  useGetProjectHeaderQuery,
  useGetPublicELaunchQuery,
  useGetPublicProjectHeaderQuery,
  useGetShareableLinkLazyQuery,
} from '../../generated/graphql'
import { TranslationServices } from 'translateService'

interface MainLayoutProps {
  children: React.ReactNode
}
export const MainLayout = ({ children }: MainLayoutProps) => {
  const { anchorEl, handleClose, handleClick } = useMenuOption()
  const { pathname } = useLocation()
  const { rootState, rootDispatch } = useContext<AppContextProps>(AppContext)
  const history = useHistory()
  const [openContactDialog, setOpenContactDialog] = useState(false)
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const initialState = 'Copy Link'
  const [copyButtonText, setCopyButtonText] = useState('Copy Link')
  const [abortSignal, setAbortSignal] = useState(false)
  const [shareableLink, setShareableLink] = useState('')
  const [copied, setCopied] = React.useState(false)

  var isRoot: boolean
  var isPublic: boolean
  let purchaserParams

  if (pathname == '/' || pathname == '/p') {
    isRoot = true
  } else {
    isRoot = false
  }

  if (pathname.split('/')[1] === 'p') {
    isPublic = true
  } else {
    isPublic = false
  }

  if (!isPublic) {
    const AccessToken = getAccessToken()
    purchaserParams = `?userType=PURCHASER${encodeURIComponent(
      '&'
    )}userId=${JWT(AccessToken)?.userID!}`
  }

  const {
    data: { getProject: projectHeader } = {
      getProject: null,
    },
    loading: projectLoading,
  } = useGetProjectHeaderQuery({
    fetchPolicy: 'no-cache',
    skip: isPublic,
  })

  const {
    data: { getPublicProject: publicProjectHeader } = {
      getPublicProject: null,
    },
    loading: publicProjectLoading,
  } = useGetPublicProjectHeaderQuery({
    fetchPolicy: 'no-cache',
    skip: !isPublic,
    variables: {
      accountCode: window.location.pathname?.split('/')[1],
      eLaunchCode: window.location.pathname?.split('/')[2]?.toUpperCase(),
    },
  })

  const {
    data: { getELaunch: eLaunchData } = {
      getELaunch: null,
    },
  } = useGetELaunchQuery({
    fetchPolicy: 'no-cache',
    skip: isPublic,
  })

  const {
    data: { getPublicELaunch: publicELaunchData } = {
      getPublicELaunch: null,
    },
  } = useGetPublicELaunchQuery({
    fetchPolicy: 'no-cache',
    skip: !isPublic,
    variables: {
      accountCode: window.location.pathname.split('/')[1],
      eLaunchCode: window.location.pathname.split('/')[2]?.toUpperCase(),
    },
  })

  let project
  let eLaunch
  if (projectHeader) {
    project = projectHeader
    eLaunch = eLaunchData
  } else {
    project = publicProjectHeader
    eLaunch = publicELaunchData
  }

  const [
    getShareableLink,
    {
      data: { getShareableLink: link } = {
        getShareableLink: null,
      },
    },
  ] = useGetShareableLinkLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setShareableLink(link + purchaserParams)
    },
  })

  const handleContactDialogOpen = () => {
    setOpenContactDialog(true)
  }

  const handleShareDialogOpen = () => {
    if (!isPublic) {
      getShareableLink()
    } else {
      setShareableLink(`${window.location.href}`)
    }
    setOpenShareDialog(true)
  }

  const menuList = [
    {
      name: TranslationServices.Translate('MY_PROFILE'),
      icon: <MyProfileIcon />,
      url: '/my-profile',
    },
    {
      name: TranslationServices.Translate('CHANGE_PASSWORD'),
      icon: <ChangePasswordIcon />,
      url: '/change-password',
    },
    {
      name: TranslationServices.Translate('LOG_OUT'),
      icon: <LogoutIcon />,
      url: '/logout',
    },
  ]
  useEffect(() => {
    let abortController = new AbortController()
    setAbortSignal(abortController.signal.aborted)
    return () => {
      abortController.abort()
      setAbortSignal(abortController.signal.aborted)
    }
  }, [])

  const ProfileMenuOption = () => {
    return (
      <ProfileMenu
        id="customized-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuList.map((menu, i) => (
          <MenuItem
            key={i}
            sx={{
              padding: '10px 12px',
            }}
            onClick={() => history.push(menu.url)}
          >
            {menu.icon}
            <Typography
              variant="inherit"
              component="span"
              fontSize={fontSizes.fs_12}
              fontWeight={fontWeights.medium}
            >
              {menu.name}
            </Typography>
          </MenuItem>
        ))}
      </ProfileMenu>
    )
  }

  useEffect(() => {
    if (copyButtonText !== initialState) {
      setTimeout(() => setCopyButtonText(initialState), 1000)
    }
  }, [copyButtonText])

  const content =
    'Greetings,' +
    '\n\n' +
    `Please find the link below to view ${eLaunch?.name} event for ${project?.name}.` +
    '\n\n' +
    shareableLink +
    '\n\n' +
    'Thank you.' +
    '\n'
  const shareContent = encodeURI(content)

  const whatsAppHandler = () => {
    var win = window.open(
      `https://api.whatsapp.com/send?text=${shareContent}`,
      '_blank'
    )
    win.focus()
  }

  const mailToShareHandler = () => {
    window.open(`mailto:?subject=${eLaunch?.name}&body=${shareContent}`)
  }

  const mailToHandler = () => {
    window.open(
      `mailto:${
        project?.developmentCompany
          ? project?.developmentCompany?.email
          : project?.holdingCompany?.email
      }`
    )
  }

  const callToHandler = () => {
    window.open(
      `tel:${
        project?.developmentCompany
          ? project?.developmentCompany?.officeTel
          : project?.holdingCompany?.officeTel
      }`
    )
  }

  const mapHandler = () => {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${
        project?.developmentCompany
          ? project?.developmentCompany?.address?.address
          : project?.holdingCompany?.address?.address
      }`
    )
  }

  const ToContactDialog = (
    <ContactDialog
      fullWidth={true}
      open={openContactDialog}
      onClose={() => setOpenContactDialog(false)}
      body={
        <Box sx={{ flexGrow: 1 }} display="block">
          <Typography
            fontSize={fontSizes.fs_12}
            fontWeight={fontWeights.semibold}
            component="div"
            variant="inherit"
            display="flex"
            justifyContent="center"
            padding="5px 0px 5px 0px"
          >
            {project?.developmentCompany
              ? project?.developmentCompany?.name
              : project?.holdingCompany?.name}
          </Typography>
          <Typography
            fontSize={fontSizes.fs_12}
            fontWeight={fontWeights.semibold}
            component="div"
            variant="inherit"
            display="flex"
            justifyContent="center"
            padding="5px 0px 5px 0px"
          >
            {project?.developmentCompany
              ? project?.developmentCompany?.companyRegNo
              : project?.holdingCompany?.companyRegNo}
          </Typography>
          <Box
            sx={{
              img: {
                width: '136px',
                height: '136px',
                borderRadius: '50%',
              },
            }}
            padding="5px 0px 5px 0px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={
                project?.developmentCompany
                  ? project?.developmentCompany?.imageUrl
                  : project?.holdingCompany?.imageUrl
              }
              alt="img"
            />
          </Box>
          <Typography
            variant="inherit"
            component="div"
            className="text-icon"
            fontSize={fontSizes.fs_10}
            fontWeight={fontWeights.medium}
            padding="5px 0px 5px 0px"
          >
            <LocationOnIcon />
            {project?.developmentCompany
              ? project?.developmentCompany?.address?.address
              : project?.holdingCompany?.address?.address}
          </Typography>
          <Typography
            variant="inherit"
            component="div"
            className="text-icon"
            fontSize={fontSizes.fs_10}
            fontWeight={fontWeights.medium}
            padding="5px 0px 5px 0px"
          >
            <PhoneIcon />
            {project?.developmentCompany
              ? project?.developmentCompany?.officeTel
              : project?.holdingCompany?.officeTel}
          </Typography>
        </Box>
      }
      footer={
        <Box
          display="flex"
          width="100%"
          justifyContent="space-around"
          alignItems="center"
        >
          <Button
            sx={{
              height: '38px',
              minWidth: '38px',
              width: '38px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              margin: '0 11px',
            }}
            variant="contained"
            color="primary"
            onClick={() => callToHandler()}
          >
            <PhoneIcon />
          </Button>
          <Button
            sx={{
              height: '38px',
              minWidth: '38px',
              width: '38px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              margin: '0 11px',
            }}
            variant="contained"
            color="primary"
            onClick={() => mailToHandler()}
          >
            <EmailIcon />
          </Button>
          <GoogleMapIcon display="flex" onClick={() => mapHandler()} />
        </Box>
      }
    />
  )

  const ToShareDialog = (
    <ShareDialog
      fullWidth={true}
      open={openShareDialog}
      onClose={() => setOpenShareDialog(false)}
      header={
        <DialogHeader
          primary={
            <Box borderBottom="1px solid #fff">
              <Box display="flex" margin="2px 0px" width="100%">
                <Typography
                  fontSize="14px"
                  lineHeight="18px"
                  fontWeight={fontWeights.semibold}
                  color={colors.orange}
                  component="span"
                  flex="1 1"
                  variant="inherit"
                  className="text-icon"
                >
                  <ShareIcon />
                  {TranslationServices.Translate('SHARE_TO')}
                </Typography>
              </Box>
            </Box>
          }
        />
      }
      body={
        <Box width="100%" display="flex">
          <Box
            sx={{
              img: {
                width: '60px',
                height: '60px',
                borderRadius: '20px',
              },
            }}
            display="flex"
            margin="0px 10px 0px 0px"
          >
            <img src={project?.firstGalleryImage?.url} alt="img" />
          </Box>
          <Box sx={{ flexGrow: 1 }} display="block">
            <Box display="block">
              <Typography
                fontSize="12px"
                lineHeight="18px"
                fontWeight={fontWeights.regular}
                component="div"
                variant="inherit"
              >
                {project?.name}
              </Typography>
              <Typography
                variant="inherit"
                component="div"
                className="text-icon"
                fontSize={fontSizes.fs_10}
                fontWeight={fontWeights.regular}
              >
                <LocationOnIcon style={{ color: '#FF9800', fontSize: 14 }} />{' '}
                {project?.city + ', ' + project?.state || ''}
              </Typography>
            </Box>
            <Box sx={{ marginTop: 1 }} display="flex">
              <Typography
                variant="inherit"
                component="div"
                className="text-icon"
                fontSize={fontSizes.fs_10}
                fontWeight={fontWeights.bold}
                sx={{ marginBottom: 0.5 }}
                onClick={() => whatsAppHandler()}
              >
                <img
                  src={WhatsAppIcon}
                  style={{ width: 18, height: 18, marginRight: 10 }}
                />
                {/* Whatsapp */}
              </Typography>
              <Typography
                variant="inherit"
                component="div"
                className="text-icon"
                fontSize={fontSizes.fs_10}
                fontWeight={fontWeights.bold}
                onClick={() => mailToShareHandler()}
              >
                <EmailIcon style={{ color: '#FF9800', fontSize: 24 }} />
                {/* Email */}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
      footer={{
        link: shareableLink,
        buttons: [
          {
            children: copyButtonText,
            onClick: () => [
              setCopyButtonText('Copied'),
              navigator.clipboard.writeText(shareableLink),
            ],
          },
        ],
      }}
    />
  )

  if (projectLoading || publicProjectLoading) return null

  return (
    <LayoutRoot component="div" className={layoutClasses.root}>
      {isRoot && rootState?.header?.mode === 'LandingHeader' ? (
        !isPublic ? (
          <LandingHeader
            title={rootState?.header?.landingHeaderProps?.title}
            rightButton={{
              onClick: e => handleClick(e),
              'aria-controls': 'customized-menu',
            }}
            contactButton={{
              onClick: () => handleContactDialogOpen(),
            }}
            pdfButton={{
              onClick: () => history.push('/brochure-list'),
            }}
            shareButton={{
              onClick: () => handleShareDialogOpen(),
            }}
            badgeInvisible={true}
            productTitle={project?.name || ''}
            productAddress={project?.city + ', ' + project?.state || ''}
          />
        ) : (
          <LandingHeader
            title={rootState?.header?.landingHeaderProps?.title}
            contactButton={{
              onClick: () => handleContactDialogOpen(),
            }}
            pdfButton={{
              onClick: () => history.push('/p/brochure-list'),
            }}
            shareButton={{
              onClick: () => handleShareDialogOpen(),
            }}
            badgeInvisible={true}
            productTitle={project?.name || ''}
            productAddress={project?.city + ', ' + project?.state || ''}
          />
        )
      ) : (
        <Header
          title={rootState?.header?.headerProps?.title}
          leftButton={rootState?.header?.headerProps?.leftButton}
        />
      )}
      <Box
        component="main"
        className={layoutClasses.mainContent}
        sx={{
          top: isRoot ? (isPublic ? 114 : 130) : 66,
        }}
      >
        {!abortSignal && children}
      </Box>
      <ProfileMenuOption />
      {ToContactDialog}
      {ToShareDialog}
    </LayoutRoot>
  )
}
