import React, { lazy } from 'react'
const Login = lazy(() => import('./Login'))
const Register = lazy(() => import('./Register'))
const NotFound = lazy(() => import('./NotFound'))
const Logout = lazy(() => import('./Logout'))
const ForgotPassword = lazy(() => import('./ForgotPassword'))

export const AUTH_PATH = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  NOT_FOUND: '/404-not-found',
  LOGOUT: '/logout',
}
const AuthRoutes = [
  {
    props: { exact: true, path: AUTH_PATH.LOGIN },
    component: <Login />,
  },
  {
    props: { exact: true, path: AUTH_PATH.REGISTER },
    component: <Register />,
  },
  {
    props: { exact: true, path: AUTH_PATH.FORGOT_PASSWORD },
    component: <ForgotPassword />,
  },
  {
    props: { exact: true, path: AUTH_PATH.NOT_FOUND },
    component: <NotFound />,
  },
  {
    props: { exact: true, path: AUTH_PATH.LOGOUT },
    component: <Logout />,
  },
]

export default AuthRoutes
