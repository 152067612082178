import Cookies from 'universal-cookie';

export const SetCookies = (name:string, value:any) => {
    const cookies = new Cookies();
    cookies.set(name, value);
}

export const GetCookies = (name:string) => {
    const cookies = new Cookies();
    return cookies.get(name);
}

export const DeleteCookies = (name:string) => {
    const cookies = new Cookies();
    cookies.remove(name);
}